export const FirebaseConfig = {
	"projectId": "kabby-c78c3",
	"appId": "1:710677469045:web:ce54deee22cffbcd4ba53c",
	"databaseURL": "https://kabby-c78c3-default-rtdb.firebaseio.com",
	"storageBucket": "kabby-c78c3.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyC4HG8gKWS4cQiZlYAVkr7SYPiaYNpqv6g",
	"authDomain": "kabby-c78c3.firebaseapp.com",
	"messagingSenderId": "710677469045",
	"measurementId": "G-PY6LYEW67M"
};